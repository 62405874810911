import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "add_apply_form" }
const _hoisted_2 = { class: "clearfix" }
const _hoisted_3 = {
  key: 0,
  class: "title"
}
const _hoisted_4 = { class: "flex_need" }
const _hoisted_5 = { class: "upload_item" }
const _hoisted_6 = ["for"]
const _hoisted_7 = ["id", "accept", "onChange"]
const _hoisted_8 = {
  key: 0,
  style: {"margin-left":"15px","color":"#86909C"}
}
const _hoisted_9 = {
  key: 1,
  style: {"margin-left":"15px","color":"#86909C"}
}
const _hoisted_10 = {
  key: 0,
  class: "file_list"
}

import { ref, getCurrentInstance, watch, onMounted } from 'vue'
  import validate  from '@/types/validate'
import { useStore } from 'vuex';
  
export default /*@__PURE__*/_defineComponent({
  __name: 'AddApplyPanel',
  props: {
    panel: {}
  },
  emits: ['submit'],
  setup(__props: any, { emit: __emit }) {

  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);

  let props = __props;
  let store = useStore()
  let emit = __emit

  let form = ref({})
  let formRef = ref(null)

  let userInfoForm = ref([
   { title: '用户', key: 'account', place: '请输入用户', dis: true, rules: [{required:true,message:'请输入用户'}], length: 10},
   { title: '手机号码', key: 'phone', place: '请输入手机号码', dis: true, rules: [
    { required:true, message:'请输入手机号码' },
    { validator: validate.validPhoneOne, trigger: 'change' }
  ], length: 11 },
   { title: '电子邮箱', key: 'email', place: '请输入电子邮箱', rules: [
   { required:true, message:'请输入电子邮箱' },
    { validator: validate.validateEmail, trigger: 'change' }
  ], length: 50 },
  ])

  let fileList = ref({
    standardTimeliness: [
      { title: '申请委托书', key: 'file1', rules: [{required:true,message:'请上传申请委托书'}], tip: '请上传体积小于 2MB 的 .PDF 格式的扫描影印件', accept: '.pdf,.PDF' },
      { title: '申请列表', key: 'file2', rules: [{required:true,message:'请上传申请列表'}], tip: '请上传体积小于 2MB 的 .XLSX 或 .XLS 格式的文件', accept: '.xlsx,.xls,.XLS,.XLSX' },
    ],
    standardProject: [
      { title: '编制说明', key: 'file1', rules: [{required:true,message:'请上传编制说明'}], tip: '请上传体积小于 2MB 的 .DOC 或 .DOCX 格式的文件', accept: '.doc,.docx,.DOC,.DOCX' },
      { title: '申请表', key: 'file2', rules: [{required:true,message:'请上传申请表'}], tip: '请上传体积小于 2MB 的 .PDF 格式的扫描影印件', accept: '.pdf,.PDF' },
      { title: '标准草案', key: 'file3', rules: [{required:true,message:'请上传标准草案'}], tip: '请上传体积小于 2MB 的 .DOC 或 .DOCX 格式的文件', accept: '.doc,.docx,.DOC,.DOCX' },
      { title: '业务委托书', key: 'file4', rules: [{required:true,message:'请上传业务委托书'}], tip: '请上传体积小于 2MB 的 .PDF 格式的扫描影印件', accept: '.pdf,.PDF' },
    
    ],
    applicationStandard: [
    { title: '申请委托书', key: 'file1', rules: [{required:true,message:'请上传申请委托书'}], tip: '请上传体积小于 2MB 的 .PDF 格式的扫描影印件', accept: '.pdf,.PDF' },
    { title: '申请列表', key: 'file2', rules: [{required:true,message:'请上传申请列表'}], tip: '请上传体积小于 2MB 的 .XLSX 或 .XLS 格式的文件', accept: '.xlsx,.xls,.XLS,.XLSX' },
    ],
    batchImport: [
    { title: '导入文件', key: 'file9', rules: [{required:true,message:'请上传申请委托书'}], accept: '.xlsx,.xls,.XLS,.XLSX' },
    ],
  })
  
  watch(() => store.state.userInfo, (n, o) => {
    if(n) {
      form.value = {
        ...form.value,
        account: store.state.userInfo.account,
        phone: store.state.userInfo.phone,
        email: store.state.userInfo.email,
      }
    }
  }, {
    immediate: true
  })
  onMounted(() => {
    (fileList.value as any)[props.panel as any].forEach((item: any) => {
      (form.value as any)[item.key] = ''
    })
    
  })

  function  handleSubmit({values, errors}: { [key: string]: any }) {
    if(errors) {
      return;
    }else {
      emit('submit', form.value)
    }
  }

  function resetClick() {
    (formRef.value as any).resetFields();
    (formRef.value as any).clearValidate();
  }

  function download() {
    globalProperties.$API.DownloadTemplateFile().then((res: any) => {
      if(res.data.code == 200) {
        window.location = globalProperties.$API.DownloadFile({
          fileName: res.data.data.fileName,
          path: res.data.data.filePath
        });
      }
    })
  }


  function uploadFile(e: any, item: any) {
    const file = e.target.files[0];
    let type = item.accept.split(',')
    if(!file) return;
    if( (form.value as any)[item.key]) return  globalProperties.$message.warning(`上传文件不能超过 1 个！`);
    if( type.filter((line: any) => { return file.name.indexOf(line) == -1 }).length == type.length ) return globalProperties.$message.error(`上传的文件格式必须为 ${ item.accept }`);
    if(file.size > 2*1024*1024) return globalProperties.$message.error(`上传的文件大小必须小于 2MB`);

    (form.value as any)[item.key + 'Name'] = file.name;
    (form.value as any)[item.key] = file;
  }

  function fileDelete(item: any) {
    (form.value as any)[item.key] = '';
    (form.value as any)[item.key + 'Name'] = ''
  }


return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_icon_file = _resolveComponent("icon-file")!
  const _component_icon_delete = _resolveComponent("icon-delete")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$props.panel == 'batchImport' ? '批量导入' : '提交申请') + " ", 1),
      (_ctx.$props.panel == 'batchImport')
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "pull-right pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/searchCenter')))
          }, "返回检索中心"))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_form, {
      class: "form_panel",
      model: _unref(form),
      ref_key: "formRef",
      ref: formRef,
      onSubmit: handleSubmit
    }, {
      default: _withCtx(() => [
        (_ctx.$props.panel != 'batchImport')
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, "基本信息"))
          : _createCommentVNode("", true),
        (_ctx.$props.panel != 'batchImport')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(userInfoForm), (item) => {
                  return (_openBlock(), _createBlock(_component_a_form_item, {
                    key: item.key,
                    field: item.key,
                    label: item.title,
                    rules: item.rules,
                    "validate-trigger": ['change','input']
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        modelValue: (_unref(form) as any)[item.key],
                        "onUpdate:modelValue": ($event: any) => (((_unref(form) as any)[item.key]) = $event),
                        placeholder: item.place,
                        disabled: item.dis,
                        "show-word-limit": "",
                        "max-length": item.length,
                        "allow-clear": ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "disabled", "max-length"])
                    ]),
                    _: 2
                  }, 1032, ["field", "label", "rules"]))
                }), 128))
              ]),
              _createVNode(_component_a_divider),
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "title" }, "附件", -1))
            ], 64))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_unref(fileList) as any)[_ctx.$props.panel as any], (item, index) => {
          return (_openBlock(), _createBlock(_component_a_form_item, {
            class: "upload_form",
            key: item.key,
            field: item.key,
            label: item.title,
            rules: item.rules,
            "validate-trigger": ['change','input']
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _withDirectives(_createVNode(_component_a_input, {
                  modelValue: (_unref(form) as any)[item.key + 'Name'],
                  "onUpdate:modelValue": ($event: any) => (((_unref(form) as any)[item.key + 'Name']) = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"]), [
                  [_vShow, false]
                ]),
                _createElementVNode("label", {
                  for: 'upload' + index
                }, [
                  _createElementVNode("input", {
                    id: 'upload' + index,
                    style: {"display":"none"},
                    type: "file",
                    accept: item.accept,
                    onChange: (e) => { uploadFile(e, item) }
                  }, null, 40, _hoisted_7),
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "arco-btn arco-btn-primary arco-btn-shape-square arco-btn-size-medium arco-btn-status-normal" }, "选择文件", -1))
                ], 8, _hoisted_6),
                (item.tip)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(item.tip), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_9, [
                      _createElementVNode("span", {
                        class: "pointer",
                        style: {"color":"#0956A8"},
                        onClick: download
                      }, "点此下载模板"),
                      _cache[3] || (_cache[3] = _createTextVNode("。按模板格式填入内容后，将 Excel 文件上传。 "))
                    ]))
              ]),
              ((_unref(form) as any)[item.key])
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("span", null, [
                      _createVNode(_component_icon_file),
                      _createElementVNode("span", null, _toDisplayString((_unref(form) as any)[item.key].name), 1)
                    ]),
                    _createVNode(_component_icon_delete, {
                      onClick: ($event: any) => (fileDelete(item))
                    }, null, 8, ["onClick"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["field", "label", "rules"]))
        }), 128)),
        _createVNode(_component_a_divider),
        (_ctx.$props.panel != 'batchImport')
          ? (_openBlock(), _createBlock(_component_a_form_item, { key: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  "html-type": "submit"
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("提交")
                  ])),
                  _: 1
                }),
                _createVNode(_component_a_button, { onClick: resetClick }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("重置")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_a_form_item, { key: 3 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  "html-type": "submit"
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("提交")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
}

})